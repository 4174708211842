import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {
    change_order_request_sending,
    close_order,
    change_user_name,
    open_error,
    open_error_sterl,
    open_sber_modal,
    set_restaurant,
    get_actual_restaurants,
    change_payment_type,
    change_payment_type_available,
    setPaymentTypeSelected,
} from "../../redux/auth-reducer";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import cards_image from '../../images/cards_type_mini_short.png';
import map_market from '../../images/map_point_shadow.svg'
import $api from "../../http";
import {PAYMENT_TYPE_ONLINE, PAYMENT_TYPE_SBP, RESTAURANTS_UPDATE_INTERVAL_IN_SECONDS} from "../../Constants";
import Loader from "../Loader/Loader";
import sbpLogo_no_text from '../../images/sbpLogo_no_text.svg';

const Order_modal = (props) => {
    let dispatch = useDispatch();
    let order_modal_show = useSelector(state => state.authPage.order_modal_show);
    let currentCityId = useSelector(state => state.authPage.currentCity);
    let citys = useSelector(state => state.authPage.citys);
    let city = citys.hasOwnProperty(currentCityId) ? citys[currentCityId] : {};
    let order_request_sending = useSelector(state => state.authPage.order_request_sending);

    let dopProducts = city.hasOwnProperty('dopProducts')    ? city.dopProducts  : null;
    let set_name = city.hasOwnProperty('set_name')          ? city.set_name     : null;
    let old_price = city.hasOwnProperty('old_price')        ? city.old_price    : null;
    let new_price = city.hasOwnProperty('new_price')        ? city.new_price    : null;
    let setsInCart = city.hasOwnProperty('setsInCart')      ? city.setsInCart   : null;

    let rests = city.restaurants;

    let paymentType = useSelector(state => state.authPage.paymentType);
    let paymentTypeAvailable = useSelector(state => state.authPage.paymentTypeAvailable);
    let paymentTypeSelected = useSelector(state => state.authPage.paymentTypeSelected);

    const changePaymentType = (paymentType) => {
        dispatch(change_payment_type(paymentType));
        dispatch(setPaymentTypeSelected(true));
    }

    useEffect(() => {

        if (true === order_modal_show) {
            dispatch(get_actual_restaurants());

            setInterval(function() {
                dispatch(get_actual_restaurants());
            }, RESTAURANTS_UPDATE_INTERVAL_IN_SECONDS * 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order_modal_show]);

    let currentRest = useSelector(state => state.authPage.currentRest);
    let name = useSelector(state => state.authPage.name);
    let nameAT = useSelector(state => state.authPage.nameAT);

    let set_id = city.set_id;

    let sum_old_price = old_price * setsInCart;
    let sum_new_price = new_price * setsInCart;

    for (let key in dopProducts) {
        sum_old_price += dopProducts[key]['count'] * dopProducts[key]['price'];
        sum_new_price += dopProducts[key]['count'] * dopProducts[key]['price'];
    }

    let mapRef;

    let currentRestaurant = currentRest !== 0 ? rests[currentRest] : null;

    useEffect(() => {

        if(currentRestaurant !== null && currentRestaurant.isOnlinePaymentSBPAvailable === true) {
            dispatch(change_payment_type_available(true));
        }
        
        if(currentRestaurant !== null && currentRestaurant.isOnlinePaymentSBPAvailable === true && paymentTypeSelected === false) {
            dispatch(change_payment_type(PAYMENT_TYPE_SBP));
        }

    }, [currentRestaurant]);

    let SelectRestaurantMap = (e) => {
        mapRef.setCenter(e.originalEvent.target.geometry.getCoordinates(), 16);
        dispatch(set_restaurant(e.originalEvent.target.options.get('restId')));
    }

    let select_change = (e) => {
        dispatch(set_restaurant(e.target.value));
        if (e.target.value !== 0) mapRef.setCenter( [rests[e.target.value]['latitude'],rests[e.target.value]['longitude']], 16)
    }

    let send_order = () => {
        function readCookie(name) {
            let matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : false;
        }
        let referral_link = readCookie('referral_link');



        let dishes = [{"dishId": set_id, "amount": setsInCart}];
        for (let key in dopProducts) {
            if (dopProducts[key]['count'] > 0) {
                dishes.push({"dishId": key, "amount": dopProducts[key]['count']});
            }
        }

        dispatch(change_order_request_sending(true));

        $api.post('/order/send', {
            "cityId": currentCityId,
            "userAccessToken": localStorage.getItem('accessToken'),
            "deliveryType": "pickup",
            "restaurantId": currentRest,
            "paymentType": paymentType,
            "paymentOnlineReturnUrl": "https://den-sushi.ru/",
            "paymentOnlineFailUrl": "https://den-sushi.ru/",
            "personsCount": 1,
            //"datetime": Date.now() / 1000 + 43200,
            "disiredTimePeriod": rests[currentRest]['daySushiPeriod'],
            "notCallBack": true,
            "dishes": dishes,
            "name": name,
            "referralLink": referral_link
        }).then((response) => {
            if(response.data.result === true) {
                if (response.data.linkForOnlinePayment!='') {
                    window.location.href = response.data.order.linkForOnlinePayment;
                } else {
                    dispatch(close_order());
                }
            } else {
                /*if(currentRest == 50 || currentRest == 48){
                    dispatch(open_error_sterl()); 
                }
                else{
                */
                    dispatch(open_error());
                //}
            }
            dispatch(change_order_request_sending(false));
        }).catch(function (){
            dispatch(open_error());
            dispatch(change_order_request_sending(false));
        });
    }

    if(Object.keys(city).length === 0){return(<></>);}


    const isOrderButtonDisabled = () => {
        if (
            
            name === null || 
            (name !== null && 0 === name.length) ||
            currentRest === 0 ||
            !rests[currentRest]['daySushiAvailable'] ||
            order_request_sending
        ) {
            return true
        }

        return false;
    }

    return (
        <Modal show={order_modal_show} onHide={() => dispatch(close_order())} dialogClassName="order_modal" centered>
            <div className="close_modal" onClick={() => dispatch(close_order())}></div>
            {/*<h3>Откуда заберете?</h3>*/}
            <h3>Детали заказа</h3>

            <Loader/>

            <div className={'row'}>
                {
                    !nameAT ?
                        <div className={'col-md-6'}>
                            <div className={name === null || (name !== null && 0 === name.length) ? 'form_div warning' : 'form_div'}>
                                <label htmlFor="name">Имя:</label>
                                <input type="text"
                                       placeholder="Ваше имя"
                                       value={name}
                                       required={true}
                                       onChange={(e) => dispatch(change_user_name(e.target.value))}/>
                            </div>
                        </div>
                        : ''
                }
            </div>

            <h5>Ваш заказ:</h5>
            <ul className="cart_list">
                <li>
                    <div className="cart_product_name">{set_name}</div>
                    <div className="counts_set"> x {setsInCart}</div> - {new_price * setsInCart} ₽
                </li>
                <li>
                    <div className="cart_product_name">Палочки одноразовые</div>
                    <div className="counts_set"> x {setsInCart}</div> - 0₽
                </li>
                {Object.keys(dopProducts).map(key => (
                    dopProducts[key]['count'] === 0 ? '' :
                        <li key={'dop_products_'+key}>
                            <div className="cart_product_name">{dopProducts[key]['name']}</div>
                            <div className="counts_set"> x {dopProducts[key]['count']} </div> - {dopProducts[key]['price'] * dopProducts[key]['count']} ₽
                        </li>
                ))}
            </ul>

            <button className="change_order" onClick={() => dispatch(close_order())}>Изменить заказ</button>
            <div className={'row select_rest_row'}>
                {Object.keys(rests).length > 1 
                    ?   <div className={'col-md-6 mb-3 mb-sm-0'}>
                            <label>Выберите ресторан:</label>
                            <select value={currentRest} onChange={select_change}>
                                {Object.keys(rests).length > 1 ? <option disabled={true} value={0}>Выберите ресторан</option> : ''}
                                {
                                    Object.keys(rests).map(key => (
                                        <option key={'option_'+key} value={key}>{rests[key]['name']} ({rests[key]['address']})</option>
                                    ))
                                }
                            </select>
                        </div>
                    : ''}
                
                <div className={'col-md-6'}>
                    <label>Примерное время получения:</label>
                    <div className={'taking_time'}>{currentRest !== 0 ? rests[currentRest]['daySushiPeriod'] : '-'}</div>
                </div>
            </div>

            <div className={'map_container'}>
                <YMaps>
                    <Map instanceRef={ref => (mapRef = ref)} defaultState={
                        {
                            center: currentRest !== 0 ? [rests[currentRest]['latitude'],rests[currentRest]['longitude']] : [city.latitude, city.longitude],
                            zoom: currentRest !== 0 ? 13 : 10,
                            controls: ['zoomControl']
                        }
                    }
                         modules={['control.ZoomControl']}
                         width="100%"
                         height="250px"
                    >
                        {
                            Object.keys(rests).map(key => (
                                <Placemark
                                    key={'placemark_'+key}
                                    modules={['geoObject.addon.hint']}
                                    defaultGeometry={[rests[key]['latitude'],rests[key]['longitude']]}
                                    defaultProperties={{
                                        hintContent: rests[key]['name'],
                                    }}
                                    defaultOptions={{
                                        iconLayout: 'default#image',
                                        iconImageHref: map_market,
                                        iconImageSize: [38, 45],
                                        iconImageOffset: [-17, -42],
                                        restId: key
                                    }}
                                    onClick={(e) => SelectRestaurantMap(e)}
                                />
                            ))
                        }
                    </Map>
                </YMaps>
            </div>

            <div className="cart_sum_block">
                <div className='payment_container'>

                    {
                        paymentTypeAvailable &&
                        <div className="type_oplaty">
                            <div className="payment-block-online">
                                <div className="payment-block-online-child">
                                    <div className="payment-block-name">Оплатить онлайн</div>
                                    <div className="label-radio form_radio">
                                        <input onChange={() => changePaymentType(PAYMENT_TYPE_SBP)}
                                            type="radio"
                                            checked={paymentType === PAYMENT_TYPE_SBP}
                                            id="order_payment_type_4"
                                            className="lsp-order-paymenttype-input lsp-js-payment-type lsp-js-payment-type-SBP"/>

                                        <label htmlFor="order_payment_type_4">СБП
                                            <img className="sbp_logo" src={sbpLogo_no_text}/>
                                        </label>
                                    </div>

                                    <div className="label-radio form_radio">
                                        <input onChange={() => changePaymentType(PAYMENT_TYPE_ONLINE)}
                                            type="radio"
                                            checked={paymentType === PAYMENT_TYPE_ONLINE}
                                            id="order_payment_type_3"
                                            className="lsp-order-paymenttype-input lsp-js-payment-type lsp-js-payment-type-ECARD"/>
                                        <label htmlFor="order_payment_type_3">Банковская карта<br/></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="price_block">
                        <h5>Стоимость итого:</h5>
                        <div className="old_price"><span>{sum_old_price}</span></div>
                        <div className="new_price"><span>{sum_new_price}</span>₽</div>
                    </div>

                    <div className='clearfix'></div>
                </div>

                <button onClick={send_order} className="pay_order"
                        disabled={isOrderButtonDisabled()}>
                    {0 !== currentRest && true === rests[currentRest].daySushiIsPaidInRestaurant ? 'Оформить' : 'Оплатить'}
                </button>
            </div>

            {
                0 !== currentRest && true === rests[currentRest].daySushiIsPaidInRestaurant ?
                    <small className="from_order">
                        В связи с ограничениями заказ необходимо будет<br/><span>оплатить при получении в ресторане</span><br/>
                    </small>
                    :
                    <small className="from_order">
                        <span>Отменить заказ после оплаты будет нельзя!</span><br/>
                        <span>Важно:</span> Если вы не заберете заказ по истечению часа после готовности заказа, он будет
                        списан.<br/>Возврат средств будет невозможен.<br/><br/>
                        После нажатия на кнопку «&lrm;Оплатить заказ» вы будете перенаправлены на страницу оплаты (<a
                        className="popup-btn" onClick={() => dispatch(open_sber_modal())}>подробнее</a>)<br/>
                        {/* <img alt="mini_cards" className={'mini_cards_image'} src={cards_image}/> */}
                    </small>
            }

        </Modal>
    )
};

export default Order_modal;
