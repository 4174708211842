import React from 'react';
import logo from '../../images/logo_svg.svg';
import {useDispatch, useSelector} from "react-redux";
import {open_roll_fourth, open_roll_third, open_roll_second, open_roll_first, open_soev_sous, open_imbir, open_vasabi, open_spais, open_unagi, open_oreh, open_cezar, open_firm, open_soul, open_pal, open_referral} from "../../redux/auth-reducer";

const Questions = (props) => {
    let citys = useSelector(state => state.authPage.citys)
    let current_city_id = useSelector(state => state.authPage.currentCity)
    let city = citys[current_city_id];
    let dispatch = useDispatch();

    return (
        <section id="questions">
            <div className="container">
                <div className="questions">
                    <div className="questions_left"></div>
                    <div className="questions_text">
                        <h2 className="major">Ответы на вопросы</h2>
                        {
                            city.questions.map((el,i) => (
                                <div className="qa_block" key={'quest_' + i}>
                                            <div className="qa_block_question"><span>{i + 1}</span> {el.question}</div>
                                            {el.answer == 'WHAT_INCLUDE' 
                                                ? <div className="qa_block_answer">
                                                    {}
                                                    <a className="popup-btn cursor_pointer" onClick={() => dispatch(open_roll_first())}>Ролл Рыбокоп</a>,&nbsp; 
                                                    <a className="popup-btn cursor_pointer" onClick={() => dispatch(open_roll_second())}>Ролл Кентукки</a>,&nbsp;<br></br>
                                                    <a className="popup-btn cursor_pointer" onClick={() => dispatch(open_roll_third())}>Ролл Лосось Хот</a> ,&nbsp;
                                                    <a className="popup-btn cursor_pointer" onClick={() => dispatch(open_roll_fourth())}>Ролл Мадагаскар</a> и&nbsp;
                                                    <a className="popup-btn cursor_pointer" onClick={() => dispatch(open_pal())}>палочки</a>. 

                                                    </div>
                                                : <div className="qa_block_answer">{el.answer}</div>}
                                            
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Questions;