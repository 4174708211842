import React from 'react';
import Flip from '../../Flip';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import QRCode from 'react-qr-code';
import {useDispatch, useSelector} from "react-redux";
import {
    change_auth_modal_phone,
    change_user_name,
    decrease_dops,
    decrease_sets,
    get_actual_restaurants, increase_dops,
    increase_sets, nextPage, open_auth_modal,
    open_order
} from "../../redux/auth-reducer";
import InputMask from "react-input-mask";

import SETDAYSUSHI from '../../images/setdaysushibig.png';
import Loader from "../Loader/Loader";

const Banner = (props) => {
    let now_city = props.citys[props.currentCityId];
    let d = Math.floor(now_city.seconds_to_start / (3600 * 24));
    let h = Math.floor(now_city.seconds_to_start % (3600 * 24) / 3600);
    let m = Math.floor(now_city.seconds_to_start % 3600 / 60);
    let s = Math.floor(now_city.seconds_to_start % 3600 % 60);

    // корзина
        let dispatch = useDispatch();
        let currentCityId = useSelector(state => state.authPage.currentCity);
        let citys = useSelector(state => state.authPage.citys);
        let currentCity = citys.hasOwnProperty(currentCityId) ? citys[currentCityId] : {};
        let dopProducts = currentCity.hasOwnProperty('dopProducts')    ? currentCity.dopProducts  : null;
        let set_name = currentCity.hasOwnProperty('set_name')          ? currentCity.set_name     : null;
        let old_price = currentCity.hasOwnProperty('old_price')        ? currentCity.old_price    : null;
        let new_price = currentCity.hasOwnProperty('new_price')        ? currentCity.new_price    : null;
        let setsInCart = currentCity.hasOwnProperty('setsInCart')      ? currentCity.setsInCart   : null;
        let isAuth = useSelector(state => state.authPage.isAuth);

        let sum_old_price = old_price * setsInCart;
        let sum_new_price = new_price * setsInCart;

        for(let key in dopProducts){
            sum_old_price+=dopProducts[key]['count']*dopProducts[key]['price'];
            sum_new_price+=dopProducts[key]['count']*dopProducts[key]['price'];
        }

        let next_cart_step = () => {
            dispatch(open_order());
        }
    // конец корзины

    let queen_block_2 = () =>{
        if(props.orderIsWaitPaid){
            return (<><UserIcon /> {props.name ? props.name + ',' : ''} ждём поступления оплаты от банка.<br/>Оплата поступает <b>в течение 1 минуты</b>.</>);
            //return (<><UserIcon /> {props.name ? props.name + ',' : ''} ждём поступления оплаты от банка,<br/>Оплата поступает <b>в течение 1 минуты</b>.<br />QR-код - генерируется</>);
        }
        if(props.orderIsReady){
            return (<>
                <UserIcon /> {props.name ? props.name + ',' : ''} ваш заказ готов<br/>
                {props.orderNumber ? <div className="new_order_number">#{props.orderNumber}</div> : ''}
                <b>{props.orderCountSets > 1 ? `${props.orderCountSets} набора` : `${props.orderCountSets}  набор`} </b>День суши на сумму <b>{props.orderSum} ₽</b><br/>
                на {props.orderAddress}<br />Скажите кассиру номер заказа, чтобы забрать ваш заказ
                <br /><br />{props.lotteryList !== '' ? 'Номера билетов розыгрыша: ' : ''}
                <b className="ticket">{props.lotteryList !== '' ? props.lotteryList : ''}</b>
                {
                    currentCityId == 1 || currentCityId == 12 || currentCityId==14
                        ? 
                <>
                <button className="referral_btn" onClick={props.open_referral}>Получить купон на 100 ₽</button></>
                 : ''}
            </>);
        }
        if(props.orderIsClose){
            return (<><UserIcon /> {props.name ? props.name + ',' : ''} ваш заказ завершен! <br/>Но у нас ещё много выгодных предложений на <a href="https://sushifuji.ru">sushifuji.ru</a>
            <br /><br />{props.lotteryList !== '' ? 'Номера билетов розыгрыша: ' : ''}
            <b className="ticket">{props.lotteryList !== '' ? props.lotteryList : ''}</b>
            {
                    currentCityId == 1 || currentCityId == 12 || currentCityId==14
                        ? 
                <>
                <button className="referral_btn" onClick={props.open_referral}>Получить купон на 100 ₽</button></>
                 : ''}
            </>);
        }
        return (
            <><UserIcon /> {props.name ? props.name + ',' : ''} ваш заказ 
            {props.orderNumber ? <div className="new_order_number">#{props.orderNumber}</div> : ''}<br/>
            <b>{props.orderCountSets > 1 ? `${props.orderCountSets} набора` : `${props.orderCountSets}  набор`} </b>День суши на сумму <b>{props.orderSum} ₽</b><br/>
            {/*props.orderNumberQueue ? <>, вы <b>{props.orderNumberQueue} в очереди!</b> ваш заказ <b>№{props.orderNumber}</b></> : ''*/}
                {props.orderAddress ? <> на {props.orderAddress}<br/></> : ''}
                {props.orderTimePeriod ? <>будет готов <b>{props.orderTimePeriod}</b>.</> : ''}
                {/*<br />{props.orderNumber ? 'Покажите кассиру QR-код, чтобы забрать ваш заказ' : 'QR код для получения заказа появится в ближайшее время'}*/}
                <br />{props.orderNumber ? 'Скажите кассиру номер заказа, чтобы забрать ваш заказ' : 'Номер заказа для получения появится в ближайшее время'}
                
                <br /><br />
                {
                    currentCityId == 1 || currentCityId == 12 || currentCityId==14
                        ? 
                <>
                <button className="referral_btn" onClick={props.open_referral}>Получить купон на 100 ₽</button></>
                 : ''}
                
                {props.lotteryList !== '' ? 'Номера билетов розыгрыша: ' : ''}
                {/* <button className="referral_btn" 
                                                                                    onClick={props.open_referral}
                >Получить купон на 100 ₽</button> */}
                <b className="ticket">{props.lotteryList !== '' ? props.lotteryList : ''}</b>




            </>
        )
    };
    
    
    return (
        <section id="banner"  className={props.orderResolution ? 'banner_step_2' : 'banner_step_1'}>
            <div className="container position-relative">
            <div className="sert_alert">
            
            Заказы принимаются в порядке живой очереди, прогнозируемое время указано при оформлении заказа (зависит от ресторана). Предварительный заказ сделать нельзя.
                </div>
                <div className="block_form">
                    <div className="set_day_sushi">
                        <img className="set_day_sushi_img_big" src={SETDAYSUSHI} alt="Набор день суши"/>
                    </div>
                    <div className={props.orderResolution ? "mobile_phone true" : "mobile_phone false" } data-product-id="{MAIN_PRODUCT_ID}">
                        {
                            props.orderResolution
                                ?
                                    <>
                                        <div className={'text_1_order_res'}>Специальный набор</div>
                                        <div className={'text_2_order_res'}>«День суши 2024»</div>
                                        <div className="set_desc">
                                            из 16 кусочков<br/>
                                        <a className="popup-btn" onClick={props.open_roll_first}>Ролл Рыбокоп</a>, <a className="popup-btn" onClick={props.open_roll_second}>Ролл Кентукки</a>,<br></br> <a className="popup-btn" onClick={props.open_roll_third}>Ролл Лосось Хот</a> и <a className="popup-btn" onClick={props.open_roll_fourth}>Ролл Мадагаскар</a>
                                        </div>
                                        <div className="prices_blocks">
                                        <div className="old_price">
                                            <span>{now_city.old_price}</span>
                                        </div>
                                        <div className="new_price">
                                            <span>{now_city.new_price}</span> ₽
                                        </div>
                                    </div>                                       
                                        <div className="clearfix"></div>

                                        <Loader
                                            color={'#FF0000'}
                                            width={120}
                                            height={120}
                                            style={{marginTop: '20px'}}
                                        />

                                        {
                                            now_city.seconds_to_start === -1 ? '' :
                                                now_city.seconds_to_start > 0
                                                    ? <div className="phone_order_start">
                                                        День суши скоро начнётся!<br/>
                                                        6 октября в {now_city.timefrom} здесь появится форма для создания заказа<br/>
                                                    </div>
                                                    : (props.setsCount == 0
                                                    ? <div className="phone_order_on_site">
                                                        Все наборы «День суши закончились :(<br/>
                                                        Но у нас на сайте ещё много выгодных предложений!<br/>
                                                        <a href="https://sushifuji.ru" className="button">Перейти на сайт</a>
                                                    </div>
                                                    : <div className="phone_order">
                                                        <div className="prices_blocks">
                                                            <div className="old_price">
                                                                <span>{now_city.old_price * now_city.setsInCart}</span>
                                                            </div>
                                                            <div className="new_price">
                                                                <span>{now_city.new_price * now_city.setsInCart}</span> ₽
                                                            </div>
                                                            <div className="flag">
                                                                <span>Акция -{Math.floor((1 - (now_city.new_price / now_city.old_price)) * 100)}%</span><br/>
                                                                в День суши
                                                            </div>
                                                        </div>
                                                            <div className="clearfix"></div>

                                                            {/*корзина начало*/}
                                                                <div className='cart_modal'>

                                                                    {<ul className="cart_list">
                                                                        <li key={'cart_product_0'}>
                                                                            <div className="cart_product_name">{set_name}</div>
                                                                            <div className="counts_set">

                                                                                <a className="count_minus" onClick={() => dispatch(decrease_sets())}>-</a> <div className="count_in_cart">{setsInCart}</div>

                                                                                {
                                                                                    currentCity.max_sets_by_user <= currentCity.setsInCart &&
                                                                                    <a style={{opacity: '30%'}} className="disabled count_minus">+</a>
                                                                                }
                                                                                {
                                                                                    currentCity.max_sets_by_user > currentCity.setsInCart &&
                                                                                    <a className="count_minus" onClick={() => dispatch(increase_sets())}>+</a>
                                                                                }

                                                                            </div>
                                                                            <div className="cart_position_sum">{new_price * setsInCart} ₽</div>
                                                                        </li>
                                                                        <li key={'cart_product_free_pal'}>
                                                                            <div className="cart_product_name">Палочки одноразовые</div>
                                                                            <div className="counts_set">
                                                                                <a className="count_minus opacity_0">-</a>
                                                                                <div className="count_in_cart">{setsInCart}</div>
                                                                                <a className="count_minus opacity_0">+</a>
                                                                            </div>
                                                                            <div className="cart_position_sum">0₽</div>
                                                                        </li>
                                                                        {Object.keys(dopProducts).map(key => (
                                                                         
                                                                            <React.Fragment key={key}>
                                                                                <li key={'cart_product_'+key}>
                                                                                        <div onClick={
                                                                                            dopProducts[key]['nickname'] == 'soev_sous' ? props.open_soev_sous : dopProducts[key]['nickname'] == 'imbir' ? props.open_imbir : dopProducts[key]['nickname'] == 'vasabi' ? props.open_vasabi : dopProducts[key]['nickname'] == 'spais' ? props.open_spais : dopProducts[key]['nickname'] == 'unagi' ? props.open_unagi : dopProducts[key]['nickname'] == 'oreh' ? props.open_oreh : dopProducts[key]['nickname'] == 'cezar' ? props.open_cezar : dopProducts[key]['nickname'] == 'firm' ? props.open_firm : dopProducts[key]['nickname'] == 'soul' ? props.open_soul : dopProducts[key]['nickname'] == 'pal' ? props.open_pal :''
                                                                                        } className="cart_product_name cart_product_name-hover">{dopProducts[key]['name']}</div>
                                                                                        <div className="counts_set">
                                                                                            <a className="count_minus" onClick={() => dispatch(decrease_dops(key))}>-</a>
                                                                                            <div className="count_in_cart">
                                                                                            {dopProducts[key]['count']}
                                                                                            </div>
                                                                                            <a className="count_plus" onClick={() => dispatch(increase_dops(key))}>+</a>
                                                                                        </div>
                                                                                        <div className="cart_position_sum">{dopProducts[key]['price']*dopProducts[key]['count']} ₽</div>
                                                                                </li>
                                                                            </React.Fragment>
                                                                        ))}

                                                                    </ul>}

                                                                    <div className="cart_sum_block">
                                                                        <h5>Стоимость итого:</h5>
                                                                        <div className="old_price"><span>{sum_old_price}</span></div>
                                                                        <div className="new_price"><span>{sum_new_price}</span> ₽</div>
                                                                        {
                                                                            isAuth == true
                                                                                ? <button className="next_cart_step" data-target="order" onClick={next_cart_step}>Оформить</button>
                                                                                : <button className="next_cart_step"
                                                                                    onClick={() => {
                                                                                        dispatch(nextPage('OPEN_ORDER_MODAL'));
                                                                                        dispatch(open_auth_modal());
                                                                                    }}>
                                                                                    Оформить
                                                                                </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            {/*корзина конец*/}
                                                        </div>
                                                    )

                                        }
                                    </>
                                :
                                    <>

                                        <Element name="queueList">
                                            <div className={'queueList'} id="queueList">
                                            <div className={props.orderIsReady ? 'queueList_2 ready' : 'queueList_2'}>{queen_block_2()}</div>
                                        </div>
                                        </Element>
                                    </>
                        }



                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </section>
    )
};

const UserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
<g>
	<g>
		<path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148    C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962    c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216    h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40    c59.551,0,108,48.448,108,108S315.551,256,256,256z"/>
	</g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
</svg>
    )
}
export default Banner;